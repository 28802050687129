import datamethods from './datamethods.js'
import {mapFields} from 'vuex-map-fields'
export default{
    mixins:[datamethods],
    computed:{
        ...mapFields([
            
            'loggedInUser',
            'securityAppItems',
            'securityAppName',
            'inventoryBelowTargetList',
            'isInventoryBelowTarget'
    

        ]),
        showOnOrderAsCheck(){
            if(window.SHOW_INVENTORY_ONORDER_AS_CHECK==undefined){
                return false
            }else{return window.SHOW_INVENTORY_ONORDER_AS_CHECK}
        }
    },
    data(){
        return{
            
        }
    },
    methods:{
        async getInventoryBelowTarget(id){
        
     
            let req = {
                ID:id,
                
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'inventorybelowtarget')
            //handle response
            if(response.Successful){
                 this.inventoryBelowTargetList =response.InventoryItemList;

                 //determin if we have items below target and NOT on order
                 var areItems =false
                 this.inventoryBelowTargetList.forEach(myFunction2)
            
                    function myFunction2(item, index, arr) {
                        let p=arr[index]
                        if(p.onOrderQty==0){
                            areItems=true
                        }
                        }
                    
                this.isInventoryBelowTarget=areItems
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  

        },
        
    }

}