<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="name" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    
                    <label class="titlelabel">{{name}} for Ticket {{ticket.id }}</label>
                    <br/>
                    <div class="leftjustified margin10">
                        <label class="titlelabel">Line Items</label>
                    </div>
                    
                    <div class="panel-inner ticketlineitem"  v-for="li in ticket.lineItems" :key="li.id" :value="li.id" >
                        <p class="leftjustified">{{ li.quantity }}</p>
                        <p class="leftjustified">{{ li.productName }}</p>
                        <p class="rightjustified">{{ toCurrency(li.amount) }}</p>
                    </div>

                    <br>

                    <div class="leftjustified margin10" v-if="ticket.tipItems.length>0">
                        <label class="titlelabel">Tip Items</label>
                    </div>
                    <div class="panel-inner paymentitem" v-for="pi in ticket.tipItems" :key="pi.id" :value="pi.id" >
                        <p class="leftjustified">{{ pi.employeeName }}</p>
                        <p class="leftjustified"></p>
                        <p class="rightjustified">{{toCurrency(pi.amount)}}</p>
                    </div>

                    <div class="vertFlex">
                        <label for class="  totaltextdetail">Subtotal: {{ toCurrency(ticket.subTotal) }}</label>
                        <label for class="  totaltextdetail discountcolor">Discount: {{ toCurrency(ticket.discountAmount)}}</label>
                        <label for class="  totaltextdetail">Tax: {{ toCurrency(ticket.tax) }}</label>
                        <label for class="  totaltextdetail">Total: {{ toCurrency(ticket.total) }}</label>
                    </div>

                    <div class="leftjustified margin10">
                        <label class="titlelabel">Payment Items</label>
                    </div>
                    <div class="panel-inner paymentitem" v-for="pi in ticket.paymentItems" :key="pi.id" :value="pi.id" >
                        <p class="leftjustified">{{ pi.typeName }}</p>
                        <p class="leftjustified">{{ pi.numberData }}</p>
                        <p class="rightjustified">{{amount(pi)}}</p>

                    </div>
          
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                </form>
                
                
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import datamethods from '../../services/datamethods.js'
    import actionmodalwide from '../controls/actionmodalwide.vue'
    export default {
        name:'ticketdetail',
        components:{
            smallbutton,
            linkbutton,
            actionmodalwide
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:false
            },
            
            ticketId:{
                type:Number
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods],
        data(){
            return{
                name:'Detail',
                trueParam:true, 
                ticket:null
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location',
                'terminalId'
     
            ]),
            
        },
        methods:{
            amount(pi){
                var amt = pi.amount
                if(pi.changeGiven>0){
                     amt = pi.amount - pi.changeGiven
                }
                return this.$options.filters.toCurrency(amt)
            },
            toDate(dt){
                return this.$options.filters.formatTime(dt);
            },
            toCurrency(amt){
                return this.$options.filters.toCurrency(amt)
            },
            
            ticketclass(g){
                let c = "guestdetails panel-inner"
                if(g.id==this.ticketId){
                    c=c+" color-highlight"
                }else{c=c+" color-inner"}
                return c
            },
            selectTicket(id){
                console.log('sel ' + id)
                this.ticketId=id
            },
            start(){
                this.refreshticket()
                this.$refs.mymodal.openModal()
            },
            async refreshticket(){
                let req = {
                    ticket:{
                        id:this.ticketId
                    }
                }  
                    //call api
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'ticket')
                    //handle response
                
                    if(response.Successful){
                    this.ticket=response.ticket

                    
                    
                    }else{
                    this.responseMessage = response.Message
                    }
                    this.pleaseWait=false;
                },
           
                
        }
    }
    
    </script>
    
    <style>
    .ticketlineitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .paymentitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .ticketfield{
        min-width:100px;
    }
    .ticketrecord{
        display:grid;
        grid-template-columns: 1fr 1fr 4fr 1fr 1fr 2fr 2fr;
    }
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }
    .panel-inner {
  padding: var(--padding-default);
  margin: var(--margin-default);
  border: var(--border-default);
  border-radius: var(--border-radius-default);

}
.discountcolor{
  color:red;
}
.totaltextdetail{
        font-size:inherit;
        margin:5px 20px 5px 5px;
        text-align:right;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    