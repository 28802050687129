<template>
    <div >
        <smallbutton toplabel="At Reorder Level" @click="start()" v-if="showButton" :buttonclass="buttonclass"/>
        
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <form >
                    <label class="titlelabel">Inventory at Reorder Level </label>
                    <br>
                    <br>
                    <div class="invrecord2" >
                        <p class="invrecordlabel bold" >Code</p>
                        <p class="invrecordlabel bold" >Product</p>
                        <p class="invrecordlabel bold" >Vendor</p>
                        <p class="invrecordlabel bold" >Location</p>
                        <p class="invrecordlabel bold rightjustified"  >On Hand</p>
                        <p class="invrecordlabel bold rightjustified" >Target</p>
                        <p class="invrecordlabel bold rightjustified"  >Prefer. Ord.</p>
                        <p class="invrecordlabel bold rightjustified"  >On Order</p>
                    </div>
                    <div class="vertScrollinv2">
                        <div v-for="g in inventoryBelowTargetList" :key="g.inventoryId" :value="g.inventoryId" class="invrecord2">

                            <p class="invrecordlabel">{{g.code}}</p>
                            <p class="invrecordlabel">{{g.name}}</p>
                            <p class="invrecordlabel">{{g.vendorName}}</p>
                            <p class="invrecordlabel">{{g.locationName }}</p>
                            <p class="invrecordlabel rightjustified">{{g.onHandQty}}</p>
                            <p class="invrecordlabel  rightjustified">{{g.targetQty}}</p>
                            <p class="invrecordlabel  rightjustified">{{g.preferredOrderQty}}</p>

                            <p class="invrecordlabel  rightjustified" v-if="!showOnOrderAsCheck">{{g.onOrderQty}}</p>
                            <div class="rightjustified">
                                <input type="checkbox" class="onordercheck " v-model="g.isOnOrder" v-if="showOnOrderAsCheck" @change="setcheck(g)"/>
                            </div>
                            
                        </div>
                    </div>

                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </form>
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import datamethods from '../../services/datamethods.js'
    import actionmodalwide from '../controls/actionmodalwide.vue'
     import lookupresult from '../controls/lookupresult.vue'
     import formentrystacked from '../controls/entry-stacked.vue'
     import inventoryservice from '../../services/inventoryservice.js'
    export default {
        name:'belowtarget',
        components:{
            smallbutton,
            actionmodalwide,
            lookupresult,
            formentrystacked
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            
         
            buttonclass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods,inventoryservice],
        data(){
            return{
                trueParam:true, 
                locationId:-1,
                responseMessage:null,

            }
        },
        computed:{
            ...mapFields([
                'pleaseWait',
                'locationList',
                'loggedInUser',
                'inventoryBelowTargetList'
           
            ]),
            
        },
        methods:{
            
            
         
            start(){
                
                this.responseMessage=null
                

                this.locationId=-1
                this.getInventoryBelowTarget(this.locationId)
                this.$refs.mymodal.openModal()
            },
         
            async setcheck(g){
                console.log(g)
                if(g.isOnOrder){
                    g.onOrderQty=1
                }else{g.onOrderQty=0}
                let req = {
                    InventoryItem:g
                    
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'updateonorder')
                //handle response
                if(response.Successful){
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            }
            
          
          
        }
    }
    
    </script>
    
    <style>
   
  
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }

.actionitem{
    width:150px;
    text-align: left;
    
    
}
.actionitemheader{
    padding-left:10px;
    margin:10px;
}
.actionitemdiv{
    border: 1px solid gray;
    padding-left:10px;
    margin:10px;
}
.invrecord2{
    text-align:left;
    background-color:var(--frame-color);
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 3fr 3fr 2fr 1fr 1fr 1fr 1fr;
 }
 .onordercheck{
    width:20px;
    margin-top:10px;
 }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    